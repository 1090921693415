// @flow
import React, { Component, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import Logo from '../../images/logo.png';

import './header.scss';

const Header = () => {
  const [isActive, setActive] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <nav
      className={`navbar is-fixed-top ${
        scrolled ? 'nav-scrolled' : 'nav-not-scrolled'
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container is-max-widescreen">
        <div className="navbar-brand">
          <Link to="/" className="navbar-logo">
            <img src={Logo} alt="kyte-one-logo" />
          </Link>
        </div>

        <div className="navbar-item">
          <OutboundLink
            href="https://forms.gle/WHS9TPVHQ5gF8VjW7"
            target="_blank"
            className="button is-primary"
          >
            Get featured here
          </OutboundLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
