// @flow
import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';
import footerBackground from '../../images/footer-background.png';

import './footer.scss';

const SocialIcons = () => {
  return (
    <nav className="social-icons" role="social-nav">
      <a
        href="https://blog.kyte.one"
        target="_blank"
        rel="noreferrer"
        className="inline-block m-4"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path
            d="M23 0.000976563H1C0.734784 0.000976563 0.48043 0.106333 0.292893 0.29387C0.105357 0.481406 0 0.73576 0 1.00098L0 23.001C0 23.2662 0.105357 23.5205 0.292893 23.7081C0.48043 23.8956 0.734784 24.001 1 24.001H23C23.2652 24.001 23.5196 23.8956 23.7071 23.7081C23.8946 23.5205 24 23.2662 24 23.001V1.00098C24 0.73576 23.8946 0.481406 23.7071 0.29387C23.5196 0.106333 23.2652 0.000976563 23 0.000976562V0.000976563ZM19.938 5.68698L18.651 6.92098C18.5966 6.96244 18.5545 7.01798 18.5293 7.08159C18.5041 7.1452 18.4967 7.21449 18.508 7.28198V16.35C18.4967 16.4175 18.5041 16.4867 18.5293 16.5504C18.5545 16.614 18.5966 16.6695 18.651 16.711L19.908 17.945V18.216H13.586V17.945L14.886 16.681C15.014 16.553 15.014 16.515 15.014 16.32V8.99098L11.393 18.191H10.904L6.691 8.99098V15.154C6.67366 15.2818 6.68562 15.4118 6.72599 15.5343C6.76636 15.6568 6.83407 15.7685 6.924 15.861L8.618 17.915V18.186H3.818V17.915L5.509 15.861C5.5982 15.7685 5.6644 15.6563 5.70227 15.5335C5.74014 15.4107 5.74861 15.2807 5.727 15.154V8.02798C5.73727 7.93061 5.72452 7.83219 5.68978 7.74065C5.65504 7.64911 5.59929 7.56701 5.527 7.50098L4.019 5.68698V5.41598H8.693L12.306 13.34L15.482 5.41598H19.938V5.68698Z"
            style={{ pointerEvents: 'none' }}
          ></path>
        </svg>
      </a>
      <a
        href="https://twitter.com/kyteone"
        target="_blank"
        rel="noreferrer"
        className="inline-block m-4"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-gray-600 hover:fill-blue-400"
        >
          <path
            d="M24 4.60078C23.1 5.00078 22.2 5.30078 21.2 5.40078C22.2 4.80078 23 3.80078 23.4 2.70078C22.4 3.30078 21.4 3.70078 20.3 3.90078C19.4 2.90078 18.1 2.30078 16.7 2.30078C14 2.30078 11.8 4.50078 11.8 7.20078C11.8 7.60078 11.8 8.00078 11.9 8.30078C7.7 8.10078 4.1 6.10078 1.7 3.10078C1.2 3.90078 1 4.70078 1 5.60078C1 7.30078 1.9 8.80078 3.2 9.70078C2.4 9.70078 1.6 9.50078 1 9.10078C1 9.10078 1 9.10078 1 9.20078C1 11.6008 2.7 13.6008 4.9 14.0008C4.5 14.1008 4.1 14.2008 3.6 14.2008C3.3 14.2008 3 14.2008 2.7 14.1008C3.3 16.1008 5.1 17.5008 7.3 17.5008C5.6 18.8008 3.5 19.6008 1.2 19.6008C0.8 19.6008 0.4 19.6008 0 19.5008C2.2 20.9008 4.8 21.7008 7.5 21.7008C16.6 21.7008 21.5 14.2008 21.5 7.70078C21.5 7.50078 21.5 7.30078 21.5 7.10078C22.5 6.40078 23.3 5.50078 24 4.60078Z"
            style={{ pointerEvents: 'none' }}
          ></path>
        </svg>
      </a>
      <a
        href="https://www.linkedin.com/company/kyteone/"
        target="_blank"
        rel="noreferrer"
        className="inline-block m-4"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-gray-600 hover:fill-blue-800"
        >
          <path
            d="M23 0.000976562H1C0.4 0.000976562 0 0.400977 0 1.00098V23.001C0 23.601 0.4 24.001 1 24.001H23C23.6 24.001 24 23.601 24 23.001V1.00098C24 0.400977 23.6 0.000976562 23 0.000976562ZM7.1 20.501H3.6V9.00098H7.2V20.501H7.1ZM5.3 7.40098C4.2 7.40098 3.2 6.50098 3.2 5.30098C3.2 4.20098 4.1 3.20098 5.3 3.20098C6.4 3.20098 7.4 4.10098 7.4 5.30098C7.4 6.50098 6.5 7.40098 5.3 7.40098ZM20.5 20.501H16.9V14.901C16.9 13.601 16.9 11.901 15.1 11.901C13.2 11.901 13 13.301 13 14.801V20.501H9.4V9.00098H12.8V10.601C13.3 9.70098 14.4 8.80098 16.2 8.80098C19.8 8.80098 20.5 11.201 20.5 14.301V20.501Z"
            style={{ pointerEvents: 'none' }}
          ></path>
        </svg>
      </a>
      <a
        href="https://t.me/kyteone"
        target="_blank"
        rel="noreferrer"
        className="inline-block m-4"
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-gray-600 hover:fill-blue-500"
        >
          <path
            d="M23.953 2.52809C23.9336 2.43947 23.8911 2.35756 23.8298 2.29065C23.7685 2.22375 23.6906 2.17422 23.604 2.14709C23.2887 2.08464 22.9624 2.10777 22.659 2.21409C22.659 2.21409 1.63003 9.77309 0.429029 10.6101C0.171029 10.7901 0.0840289 10.8951 0.0410289 11.0181C-0.166971 11.6181 0.480029 11.8761 0.480029 11.8761L5.90003 13.6421C5.99167 13.6585 6.08591 13.653 6.17503 13.6261C7.40703 12.8471 18.575 5.79209 19.224 5.55509C19.324 5.52509 19.401 5.55509 19.381 5.63009C19.123 6.53509 9.47203 15.1081 9.41903 15.1601C9.39321 15.1812 9.3731 15.2085 9.36052 15.2394C9.34795 15.2703 9.34331 15.3039 9.34703 15.3371L8.84103 20.6291C8.84103 20.6291 8.62903 22.2761 10.276 20.6291C11.444 19.4601 12.565 18.4921 13.125 18.0211C14.989 19.3081 16.994 20.7311 17.859 21.4761C18.0045 21.6169 18.1769 21.727 18.3659 21.7997C18.5549 21.8723 18.7567 21.9062 18.959 21.8991C19.2082 21.8687 19.4423 21.7632 19.63 21.5965C19.8178 21.4298 19.9503 21.2099 20.01 20.9661C20.01 20.9661 23.84 5.54309 23.968 3.47709C23.981 3.27709 23.998 3.14509 24 3.00609C24.0063 2.84536 23.9904 2.68453 23.953 2.52809Z"
            style={{ pointerEvents: 'none' }}
          ></path>
        </svg>
      </a>
    </nav>
  );
};

export default function Footer() {
  return (
    <footer>
      <div className="footer-container container is-max-widescreen">
        <ul className="footer-columns">
          <li className="row-span-2">
            <div className="footer-column space-y-4">
              <h2 className="footer-column-header">AirLyft</h2>
              <ul className="footer-column-items space-y-4">
                <li>
                  <a className="" href="https://airlyft.one/roadmap">
                    Product Roadmap
                  </a>
                </li>
                <li>
                  <a className="" href="https://airlyft.one/">
                    For Startups
                  </a>
                </li>
                <li>
                  <a className="" href="https://airlyft.one/#community">
                    For the community
                  </a>
                </li>
                <li>
                  <a className="" href="https://airlyft.one/#kols">
                    For KOLs &amp; connected individuals
                  </a>
                </li>
                <li>
                  <a className="" href="https://airlyft.one/#launchpads">
                    For Launchpads &amp; VCs
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="space-y-5 row-span-2">
            <div className="footer-column space-y-4">
              <h2 className="footer-column-header">Company</h2>
              <ul className="footer-column-items space-y-4">
                <li>
                  <a className="" href="https://www.kyte.one/">
                    About Kyte
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.kyte.one/roadmap">
                    Company Roadmap
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.kyte.one/team">
                    Team
                  </a>
                </li>
                <li>
                  <a className="" href="mailto:support@kyte.one">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="space-y-5 row-span-2">
            <div className="footer-column space-y-4">
              <h2 className="footer-column-header">Ecosystem</h2>
              <ul className="footer-column-items space-y-4">
                <li>
                  <a
                    className=""
                    href="https://www.kyte.one/papers/whitepaper.pdf"
                  >
                    Whitepaper
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    href="https://drive.google.com/file/d/12Qe5RryrJa9-xJVrf7VF_OObofeO-92w/view"
                  >
                    Tokenomics
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    href="https://docs.google.com/presentation/d/15m78KRXt9XQWrU8xNdCzChpThF6mMwbPRRiZ2osyX38"
                  >
                    Kyte Litepaper
                  </a>
                </li>
                <li>
                  <a className="" href="#">
                    Airlyft Litepaper
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="space-y-5">
            <div className="footer-column space-y-4">
              <h2 className="footer-column-header">Other Products</h2>
              <ul className="footer-column-items space-y-4">
                <li>
                  <a className="" href="https://airlyft.one/">
                    AirLyft
                  </a>
                </li>
                <li>
                  <a className="" href="https://faucet.kyte.one/">
                    Testnet Faucet
                  </a>
                </li>
                <li>
                  <a className="" href="https://test.kyte.one/">
                    Predictly Testnet
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <SocialIcons />
        <div className="footer-logo">
          <img src={logo} alt="logo" style={{ width: '12rem' }} />
        </div>
        <div className="footer-annotation">
          © Copyright 2021 Kyte Research LLC - All rights reserved
        </div>
      </div>
      <div className="footer-background">
        <img src={footerBackground} />
      </div>
    </footer>
  );
}
